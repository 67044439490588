// Font imports
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,700,700i');

// Font smoothing
@if ($smoothing == true) {
	body {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}

// Font weights
$weight-light: 			300;
$weight-regular: 		400;
$weight-bold: 			700;

// Font settings
$base-font-family: 		'Montserrat', sans-serif;
$base-font-weight: 		$weight-light;
$base-font-size: 		16px;
$base-font-color1: 		rgba(0,0,0,0.8);

// Headings
$heading-font-family:	'Montserrat', sans-serif;
$heading-font-weight: 	$weight-bold;
$heading-font-color1:	#000;
