// Set accessibility testing true/false
$testing: false;
// Set font smoothing true/false
$smoothing: true;
// Set default gutter width (settings custom gutter width in _gutter.scss)
// True = bootstrap gutter width
$defaultGutter: true;

// Import plugins
@import "plugins/animations.css";

// Import mixins
@import "mixins/abs-pos";
@import "mixins/border-radius";
@import "mixins/box-sizing";
@import "mixins/breakpoints";
@import "mixins/keyframes";
@import "mixins/opacity";
@import "mixins/transition";
@import "mixins/gutter";
@import "mixins/shorthands";

// Import settings
@import "settings/testing";
@import "settings/colors";
@import "settings/font";
@import "settings/links";
@import "settings/gutter";

// Import elements
@import "elements/button";
@import "elements/checkbox";
@import "elements/navigation";
@import "elements/slick";

// Box sizing
* {
	@include box-sizing(border-box);
}

// Body
body {
	background-color: 	$body-bg-color;
	font-family: 		$base-font-family;
	font-size: 			$base-font-size;
	font-weight: 		$base-font-weight;
	color: 				$base-font-color1;
	margin: 			0;
	padding: 			0;
	line-height:		27px;
	padding-top:  89px;

	@include breakpoint(tablet) {
		padding-top: 50px;
	}
}

// Headings
h1,h2,h3,h4,h5,h6 {
	font-family: 		$heading-font-family;
	font-weight: 		$heading-font-weight;
	color: 				$heading-font-color1;
	margin-top: 0;
	margin-bottom: 25px;
	line-height: 1;
}

// Links
a {
	// Default
	color: $link-color-default;
	text-decoration: $link-decoration-default;

	// Hover
	&:hover {
		color: $link-color-hover;
		text-decoration: $link-decoration-hover;
	}

	// Active
	&:active {
		color: $link-color-active;
		text-decoration: $link-decoration-active;
	}

	// Visited
	&:visited {
		color: $link-color-visited;
		text-decoration: $link-decoration-visited;
	}
}

// Responsive video iframes
.video-container {
	position: relative;
	padding-bottom: 56.25%; /*16:9*/
	height: 0;
	overflow: hidden;

	iframe,
	object,
	embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

// Section
section {
	padding: 50px 0;
}

// Img
img {
	max-width: 100%;
}

// Clearspace
._clearspace {
	padding: 0!important;
	margin: 0!important;
}

// Blinking
@include keyframes(blink) {
	0% {opacity: 0;}
	49%{opacity: 0;}
	50% {opacity: 1;}
}

// Paragraph
p {
	margin-top: 0;
	margin-bottom: 30px;

	@include breakpoint(mobile) {
		margin-bottom: 20px;
	}

	&:last-of-type {
		margin: 0;
	}
}