// Imports
@import "general";
@import "plugins/parsley";
@import "plugins/jquery-confirm.min";

// Inputs
.attachment,
input[type="text"],
input[type="tel"],
input[type="email"],
input[type="number"],
select,
textarea {
    width: 100%;
    height: auto;
    font-size: 16px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    padding: 15px 20px;
    outline: none;
    @include border-radius(0);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    margin-bottom: 13px;
    resize: none;
    @include transition(all .3s);

    &:focus {
        border-color: $base-color2;
    }

    // Error
    &.parsley-error {
        margin-bottom: 0;

        &:focus {
            border-color: #e74c3c;
        }
    }
}

img {
    width: auto;
    height: auto;
}

select {
    border-radius: 45px;
    padding-right: 30px;
    background-position: right 15px center;
    background-repeat: no-repeat;
    background-image: url('../img/icons/arrows.svg');
    background-size: 13px 13px;
}

.text-italic {
    font-style: italic;
}

.attachment {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    input {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        z-index: 5;
        left: 0;
        top: 0;
        opacity: 0;
        filter: alpha(opacity=0);
        cursor: pointer;
    }

    .button {
        border-radius: 0 !important;
        margin: -18px;
    }

    .parsley-errors-list {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin: 0;
        background: white;
        width: 100%;
    }

}


.parsley-errors-list {
    margin-bottom: 20px;
    margin-top: 10px;
    list-style-type: none;
    padding: 0;
    color: #e74c3c;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;

    li {
        line-height: 1.2;
        position: relative;
        padding-left: 15px;
        margin: 5px 0;
        font-weight: 400;

        &:before {
            width: 10px;
            height: 10px;
            background-image: url("../img/icons/error.svg");
            background-size: 100% 100%;
            background-position: center center;
            background-repeat: no-repeat;
            content: '';
            top: 0;
            bottom: 0;
            left: 0;
            margin: auto 0;
            position: absolute;
        }
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

// Opening
.opening {
    font-size: 14px;

    // Status
    .opening-lightbulb {
        width: 7px;
        height: 7px;
        background-color: #eeeeee;
        @include border-radius(50%);
        display: inline-block;
        margin-right: 5px;

        &._open {
            background-color: $base-color2;
        }
    }
}

.widget-col {
    p {
        margin: 0;
    }
}

// Alert
.alert.alert-info {
    width: 100%;
    display: inline-block;
    background-color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.04);
    margin-bottom: 30px;
    padding: 50px;

    @include breakpoint(tablet-landscape) {
        padding: 25px;
    }
}

// Widgets
.widget {
    width: 100%;
    display: inline-block;
    background-color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.04);
    margin-bottom: 30px;
    padding: 50px;

    @include breakpoint(tablet-landscape) {
        padding: 25px;
    }

    // Paragraph
    p {
        margin: 0;
    }

    // Header
    .widget_header {
        font-weight: 700;
        font-size: 21px;
        margin-bottom: 30px;
        display: inline-block;
        color: #000;

        @include breakpoint(mobile) {
            margin-bottom: 15px;
        }
    }

    // Widget USP
    &.widget_usp {

        // List
        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;

            // List item
            li {
                display: inline-block;
                width: 100%;
                position: relative;
                padding-left: 35px;
                margin-bottom: 20px;

                // Last of type
                &:last-of-type {
                    margin-bottom: 0;
                }

                // Icon
                &:before {
                    width: 23px;
                    height: 23px;
                    background-image: url('../img/icons/check.svg');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    background-position: center center;
                    top: 1px;
                    left: 0;
                    content: '';
                    position: absolute;
                }
            }
        }
    }

    // Widget contact
    &.widget_contact {

        // Phone link
        .telephone-link {
            color: #000;
            font-weight: $weight-bold;
            font-size: 18px;
            position: relative;
            padding-left: 22px;
            text-decoration: none;

            @include breakpoint(tablet) {
                font-size: 16px;
                padding-left: 18px;
            }

            @include breakpoint(mobile) {
                font-size: 21px;
                padding-left: 18px;
            }

            // Phone icon
            &:before {
                width: 14px;
                height: 13px;
                background-image: url('../img/icons/phone.svg');
                -webkit-background-size: 100% 100%;
                background-size: 100% 100%;
                background-repeat: no-repeat;
                background-position: center center;
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto 0;
                left: 0;
                content: '';
            }
        }

        // Contact row
        .contact-row {
            margin: 20px 0;
            display: inline-block;
        }
    }
}

// Widget row (category page)
.widget-row {

    @include breakpoint(tablet) {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;

        // Widget
        .widget {
            width: 50%;
        }
    }

    @include breakpoint(mobile) {
        display: block;

        // Widget
        .widget {
            width: 100%;
        }
    }
}

// Faq item
.faq-item {
    display: inline-block;
    width: 100%;

    // Question
    .faq-item-question {
        width: 100%;
        display: inline-block;
        font-weight: 700;
        padding: 10px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.04);
        position: relative;
        padding-right: 30px;
        cursor: pointer;
        @include transition(all .3s);
        &:hover {
            color: $base-color2;
        }

        // Icon
        &:after {
            width: 7px;
            height: 5px;
            background-image: url('../img/icons/arrow-down.svg');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center center;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto 0;
            right: 0;
            content: '';
            opacity: 0.2;
            @include transition(all .3s);
        }
    }

    // Answer
    .faq-item-answer {
        display: none;
        padding: 10px 0;
    }

    // Trigger
    &.trigger {

        // Question
        .faq-item-question {

            // Icon
            &:after {
                -webkit-transform: rotate(-180deg);
                -moz-transform: rotate(-180deg);
                -ms-transform: rotate(-180deg);
                -o-transform: rotate(-180deg);
                transform: rotate(-180deg);
            }
        }
    }
}

// Contact block
.contact-block {
    width: 100%;
    display: inline-block;
    background-color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.04);
    margin-bottom: 30px;
    padding: 50px;

    @include breakpoint(tablet-landscape) {
        padding: 25px;
    }

    // Heading
    .contact-block-heading {
        font-weight: 700;
        font-size: 26px;
        margin-bottom: 0;
        display: inline-block;
        color: #000;
        line-height: 33px;

        @include breakpoint(tablet-landscape) {
            font-size: 21px;
            line-height: 26px;
        }
    }

    // Right
    .contact-block-right {
        display: inline-block;
        float: right;

        @include breakpoint(mobile) {
            float: left;
            margin-top: 20px;
        }

        // Phone
        .telephone-link {
            color: #000;
            font-weight: $weight-bold;
            font-size: 22px;
            position: relative;
            padding-left: 22px;
            text-decoration: none;

            // Phone icon
            &:before {
                width: 17px;
                height: 17px;
                background-image: url('../img/icons/phone.svg');
                -webkit-background-size: 100% 100%;
                background-size: 100% 100%;
                background-repeat: no-repeat;
                background-position: center center;
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto 0;
                left: 0;
                content: '';
            }
        }

        // Opening
        .opening {
            text-align: right;

            @include breakpoint(mobile) {
                text-align: left;
            }
        }
    }

    // Paragraph
    p {
        font-size: 18px;

        // Link
        a {
            color: $base-font-color1;
        }
    }
}

// Pagination
.paginator {
    display: inline-block;
    width: auto;
    margin-bottom: 30px;

    // Link
    a {
        background-color: #fff;
        display: inline-block;
        padding: 15px 20px;
        border: 1px solid rgba(0, 0, 0, 0.04);
        color: $base-font-color1;
        text-decoration: none;
        font-size: 16px;
        font-weight: 400;
        border-right: 0;
        line-height: 1;
        text-align: center;

        // Active / hover
        &:hover, &.active {
            //font-weight: 700;
            color: #fff;
            background-color: #222222;
        }

        // Left / right
        &.pagination-next, &.pagination-prev {
            color: #fff;
            position: relative;

            // Icon
            &:before {
                width: 5px;
                height: 10px;
                background-size: 100% 100%;
                background-position: center center;
                background-repeat: no-repeat;
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                margin: auto;
                @include transition(all .3s);
            }

            // Hover
            &:hover {
                color: #fff;
                background-color: #fff;
            }
        }

        // Next
        &.pagination-next {
            border-right: 1px solid rgba(0, 0, 0, 0.04);

            &:before {
                background-image: url('../img/icons/arrow-right-grey.svg');
            }

            &:hover:before {
                right: -10px;
            }
        }

        // Prev
        &.pagination-prev {
            &:before {
                background-image: url('../img/icons/arrow-left-grey.svg');
            }

            &:hover:before {
                left: -10px;
            }
        }
    }
}

// Breadcrumbs
.breadcrumbs {
    position: absolute;
    top: 89px;
    padding: 20px 0;
    z-index: 99;
    left: 0;
    right: 0;
    margin: 0 auto;

    @include breakpoint(tablet) {
        top: 50px;
    }

    // Links
    a {
        color: #fff;
        font-size: 13px;
        font-weight: 300;
        text-decoration: none;
        position: relative;
        padding-right: 11px;
        margin-right: 8px;
        float: left;

        // Icon
        &:after {
            width: 4px;
            height: 6px;
            background-image: url('../img/icons/arrow-right-white.svg');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center center;
            bottom: 4px;
            right: 0;
            position: absolute;
            content: '';
        }
    }

    .itemscope, .itemchild {
        display: inline-block;
        line-height: 1;
    }

    .itemchild.last a:after {
        display: none;
    }

    // Producten
    &.producten {

        // Links
        a {
            color: $base-font-color1;

            &:after {
                background-image: url('../img/icons/arrow-right.svg');
                opacity: 0.8;
            }
        }
    }
}

// -------- Category blocks

// Wrapper (col)
.category-wrapper {
    // Remove whitespace below column
    line-height: 0;
    margin-bottom: 30px;

    @include breakpoint(mobile) {
        margin-bottom: 15px;
    }
}

// Category block
.category-block {
    position: relative;
    cursor: pointer;
    overflow: hidden;

    // Img
    img {
        width: 100%;
        @include transition(all .5s);
    }

    // Inner
    .category-block-inner {
        position: absolute;
        bottom: 30px;
        left: 45px;
        font-weight: $weight-regular;
        font-size: 18px;
        color: #fff;
        padding-right: 13px;
        display: inline-block;
        line-height: 1;

        @include breakpoint(mobile) {
            left: 25px;
        }

        // Icon
        &:after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            margin: auto 0;
            width: 5px;
            height: 7px;
            background-image: url('../img/icons/arrow-right-white.svg');
            -webkit-background-size: 100% 100%;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center center;
            content: '';
        }
    }

    // Hover
    &:hover {

        img {
            transform: scale(1.1);
        }
    }
}

// -------- Category blocks end

// Header
.header-container {
    position: relative;

    // Header block
    .inner {
        width: 580px;
        background-color: #fff;
        padding: 70px 65px;
        box-shadow: 0px 7px 51px 0px rgba(0, 0, 0, 0.15);
        margin-bottom: -60px;

        @include breakpoint(tablet-landscape) {
            width: 450px;
            padding: 35px;
        }

        @include breakpoint(mobile) {
            width: 100%;
        }
    }

    // Setup heights (dashboard header)
    &.dashboard-header {
        height: 630px;

        @include breakpoint(laptop) {
            height: 430px;
        }

        @include breakpoint(tablet-landscape) {
            height: 430px;
        }

        @include breakpoint(tablet) {
            height: 350px;
        }
    }

    // NOT dashboard header
    &:not(.dashboard-header) {
        height: 350px;

        @include breakpoint(tablet) {
            height: 300px;
        }

        // Header block
        .inner {
            width: 430px;
            padding: 40px 50px;

            @include breakpoint(tablet-landscape) {
                padding: 35px;
            }

            @include breakpoint(mobile) {
                width: 100%;
                padding: 25px;
            }

            // Headings
            h1 {
                font-size: 41px;
                line-height: 1;
                margin-bottom: 15px;
                display: inline;
                position: relative;

                @include breakpoint(tablet-landscape) {
                    font-size: 36px;
                }

                @include breakpoint(mobile) {
                    margin-bottom: 5px;
                    font-size: 31px;
                }

                // Border
                &:after {
                    height: 4px;
                    width: 46px;
                    background-color: $base-color2;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: -55px;
                    content: '';
                    margin: auto 0;
                }
            }

            p {
                color: $base-font-color1;
                font-size: 16px;
                line-height: 27px;
                margin-top: 15px;
            }
        }
    }

    // Background
    .background {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }

    // Header content
    .header-content {
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        max-width: 1170px;
        margin: 0 auto;
        left: 0;
        right: 0;
        text-align: left !important;
        padding: 0 15px;

        @include breakpoint(tablet-landscape) {
            max-width: 970px;
        }

        // Align middle
        .align-middle {
            display: table;
            width: 100%;
            height: 100%;

            // Align middle cell
            .align-middle-cell {
                vertical-align: bottom;
                display: table-cell;
            }
        }
    }

    // Dashboard header
    &.dashboard-header {

        // Header block
        .inner {

            // Headings
            h1 {
                font-size: 60px;
                line-height: 1;
                margin-bottom: 15px;
                display: inline;
                position: relative;

                @include breakpoint(tablet-landscape) {
                    font-size: 48px;
                }

                @include breakpoint(mobile) {
                    font-size: 36px;
                    margin-bottom: 5px;
                }

                // Border
                &:after {
                    height: 4px;
                    width: 46px;
                    background-color: $base-color2;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: -65px;
                    content: '';
                    margin: auto 0;
                }
            }

            h2 {
                font-size: 42px;
                color: rgba(0, 0, 0, 0.8);
                font-weight: $weight-light;
                display: inline-block;
                width: 100%;

                @include breakpoint(tablet-landscape) {
                    font-size: 36px;
                }

                @include breakpoint(mobile) {
                    font-size: 26px;
                    margin: 0;
                }
            }

            // Typed cursor
            .typed-cursor {
                height: 30px;
                width: 1px;
                display: inline-block;
                background-color: rgba(0, 0, 0, 0.5);
                position: relative;
                margin-left: 10px;

                // Typed cursor blink
                &.typed-cursor--blink {
                    -webkit-animation: blink 1s infinite;
                    -o-animation: blink 1s infinite;
                    animation: blink 1s infinite;
                }
            }

            // Button
            .button {
                font-size: 16px;
            }
        }

        // Header inner
        .header-inner {
            width: 100%;
            max-width: 45%;
            position: absolute;
            bottom: 50px;
            right: 0;
            z-index: 10;

            @include breakpoint(tablet) {
                display: none;
            }

            // Block
            .header-inner-block {
                border-left: 1px solid rgba(255, 255, 255, 0.1);
                padding-left: 20px;
                color: #fff;
                font-size: 18px;

                @include breakpoint(laptop) {
                    width: 44.66666667%;
                }

                @include breakpoint(tablet-landscape) {
                    right: 5%;
                }

                // Strong
                strong {
                    font-weight: $weight-bold;
                }

                // Link
                a {
                    color: #fff;
                    text-decoration: none;
                    font-size: 16px;
                    display: inline-block;
                    position: relative;
                    padding-right: 12px;
                    @include transition(all .3s);

                    // Icon
                    &:after {
                        width: 5px;
                        height: 7px;
                        background-image: url('../img/icons/arrow-right-white.svg');
                        -webkit-background-size: 100% 100%;
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                        background-position: center center;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        margin: auto 0;
                        content: '';
                        right: 0;
                        @include transition(all .3s);
                    }

                    &:hover {
                        &:after {
                            right: -5px;
                        }
                    }
                }
            }
        }
    }
}

// Categories
.categories {
    padding: 110px 0;

    @include breakpoint(tablet) {
        padding: 70px 0;
    }

    @include breakpoint(mobile) {
        padding: 50px 0;
    }

    // Topspace
    &._topspace {
        padding-top: 175px;

        @include breakpoint(tablet-landscape) {
            padding-top: 145px;
        }

        @include breakpoint(tablet) {
            padding-top: 120px;
        }

        @include breakpoint(mobile) {
            padding-top: 100px;
        }
    }

    // Foot
    &._foot {
        padding: 50px 0;
        background-color: #fff;
    }

    // Headings
    h2 {
        font-size: 42px;
        margin-bottom: 10px;

        @include breakpoint(tablet) {
            font-size: 36px;
        }

        @include breakpoint(tablet) {
            font-size: 32px;
        }
    }

    h3 {
        font-size: 26px;
        font-weight: $weight-light;
        color: rgba(0, 0, 0, 0.8);
        margin-bottom: 45px;
        line-height: 32px;

        @include breakpoint(tablet) {
            font-size: 24px;
        }

        @include breakpoint(tablet) {
            font-size: 21px;
            line-height: 28px;
        }
    }

    // Container
    .container {
        position: relative;
    }

    // Fixed button
    .button._sticky {
        position: absolute;
        top: 0;
        right: 0;

        @include breakpoint(tablet) {
            position: relative;
        }
    }

    // Has button
    &._hasbutton {

        @include breakpoint(mobile) {
            // Heading
            h3 {
                margin-bottom: 15px;
            }

            .button._sticky {
                margin-bottom: 30px;
            }
        }
    }
}

// Quote
section.quote-wrapper {
    padding: 0;

    // Column
    .column {
        padding: 0;
    }

    // First paragraph
    p:first-of-type {
        margin: 0;
    }

    // Quote
    .quote {
        width: 100%;
        height: auto;
        padding: 105px 0;
        -webkit-background-size: cover;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;

        @include breakpoint(tablet) {
            padding: 70px 0;
        }

        @include breakpoint(mobile) {
            padding: 50px 0;
        }

        // backgrounds
        &._bg1 {
            background-image: url('../img/quote1.jpg');
        }

        // Quote wrapper
        .quote-wrapper {
            padding-left: 105px;

            @include breakpoint(mobile) {
                padding-left: 15px;
                padding-top: 75px;
            }

            // Quote icon
            &:before {
                width: 90px;
                height: 90px;
                background-color: #000;
                background-image: url('../img/icons/quote.svg');
                -webkit-background-size: 28px 28px;
                background-size: 28px 28px;
                background-repeat: no-repeat;
                background-position: center center;
                position: absolute;
                top: 0;
                left: 15px;
                content: '';

                @include breakpoint(mobile) {
                    width: 75px;
                    height: 75px;
                }
            }
        }

        // Quote inner
        .quote-inner {
            width: 100%;
            background-color: #fff;
            padding: 50px;
            padding-bottom: 0;

            @include breakpoint(mobile) {
                padding: 25px;
                padding-bottom: 0;
            }

            // Heading
            h2 {
                font-size: 26px;
                line-height: 30px;
                padding-left: 30px;
                position: relative;
                margin: 0;

                @include breakpoint(tablet) {
                    font-size: 24px;
                }

                @include breakpoint(mobile) {
                    font-size: 16px;
                    line-height: 24px;
                    padding-left: 20px;
                }

                // Border left
                &:before {
                    width: 4px;
                    background-color: $base-color2;
                    height: 90%;
                    position: absolute;
                    margin: auto 0;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    content: '';
                }
            }

            // Body
            .quote-inner-body {
                margin: 30px 0;
                display: inline-block;

                @include breakpoint(mobile) {
                    margin-top: 20px;
                }

                // Paragraph
                p {
                    font-size: 16px;
                    line-height: 27px;
                    margin: 0;
                }
            }

            //Footer
            .quote-inner-footer {
                padding: 17px 0;
                display: inline-block;
                border-top: 1px solid #eeeeee;
                line-height: 39px;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.5);

                // Span devider
                span {
                    margin: 0 10px;

                    @include breakpoint(mobile) {
                        display: none;
                    }
                }

                // Body
                .quote-inner-footer-body {
                    display: inline-block;

                    @include breakpoint(mobile) {
                        width: calc(100% - 65px);
                        line-height: 1.5;
                    }
                }

                // Img
                img {
                    @include border-radius(50%);
                    float: left;
                    width: 39px;
                    height: 39px;
                    margin-right: 15px;
                    position: relative;

                }
            }
        }
    }
}

// Basecontent
.basecontent {
    padding: 130px 0;

    @include breakpoint(tablet-landscape) {
        padding: 110px 0;
    }

    @include breakpoint(tablet) {
        padding: 70px 0;
    }

    @include breakpoint(tablet) {
        padding: 50px 0;
    }

    // Heading
    h2 {
        font-size: 42px;
        position: relative;
        padding-bottom: 30px;
        margin-bottom: 30px;

        @include breakpoint(tablet) {
            font-size: 36px;
        }

        @include breakpoint(mobile) {
            font-size: 32px;
        }

        // Border
        &:after {
            width: 75px;
            height: 4px;
            background-color: $base-color2;
            position: absolute;
            left: 0;
            bottom: 0;
            content: '';
        }
    }

    // Img
    img {

        @include breakpoint(mobile) {
            margin-top: 30px;
        }
    }

    &.nopadding {
        padding-top: 0 !important;
    }
}


// Footer
footer {
    width: 100%;
    background-color: $base-color3;
    padding: 60px 0;

    @include breakpoint(mobile) {
        padding: 50px 0;
    }

    // Heading
    h2 {
        font-size: 26px;
        color: #fff;
        margin-bottom: 40px;

        @include breakpoint(mobile) {
            margin-bottom: 20px;
        }
    }

    // List
    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        // List item
        li {

            // Link
            a {
                color: rgba(255, 255, 255, 0.54) !important;
                text-decoration: none !important;
                font-size: 16px;
                position: relative;
                padding-right: 14px;
                @include transition(all .3s);

                // Icon
                &:after {
                    width: 4px;
                    height: 6px;
                    background-image: url('../img/icons/arrow-right-white.svg');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    background-position: center center;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto 0;
                    right: 0;
                    content: '';
                    opacity: 0.54;
                    @include transition(all .3s);
                }

                &:hover {
                    color: #fff !important;

                    &:after {
                        opacity: 1;
                    }
                }
            }
        }
    }

    // Images
    img {
        margin-right: 45px;

        &.last {
            margin-right: 0;
        }

        @include breakpoint(mobile) {
            max-width: 26.333333%;
            margin: 0 12px;
        }
    }

    // Logos
    .logos {
        .figure {
            max-width: 120px;
            display: inline-flex;
            flex-direction: column;
            margin: 0;
            img {
                @include breakpoint(mobile) {
                    max-width: none;
                }
            }
            .figure-caption {
                color: white;
                font-size: .7rem;
                font-weight: 400;
                padding-top: 5px;
                line-height: normal;
                @include breakpoint(mobile) {
                    margin-left: 12px;
                }
            }
        }
    }
}

// Footer sub
.footer-sub {
    width: 100%;
    background-color: #000000;
    padding: 40px 0;

    @include breakpoint(mobile) {
        padding: 20px 0;
    }

    // Img
    img {
        width: 185px;

        @include breakpoint(mobile) {
            margin-bottom: 20px;
        }
    }

    // Text right
    .text-right {
        text-align: right;

        @include breakpoint(mobile) {
            text-align: left;
            line-height: 1;
        }
    }

    // Links
    a {
        color: rgba(255, 255, 255, 0.3);
        text-decoration: none;
        @include transition(all .3s);

        &:hover {
            color: #fff;
        }
    }

    // Devider
    span {
        color: rgba(255, 255, 255, 0.3);
        margin: 0 5px;
        display: inline-block;

        @include breakpoint(mobile) {
            opacity: 0;
            width: 100%;
            height: 1px !important;
            line-height: 1;
            margin: 0;
        }
    }

    // Copyright
    .copyright {
        color: rgba(255, 255, 255, 0.54) !important;
        font-size: 14px;
        opacity: 0.7;
        text-align: center;
        margin-top: 30px;

        a {
            color: rgba(255, 255, 255, 0.54) !important;
        }
    }
}

// Intro
.intro {
    padding: 130px 0;

    @include breakpoint(tablet-landscape) {
        padding: 110px 0;
    }

    @include breakpoint(tablet) {
        padding: 100px 0;
    }

    // 30 bottom
    &._bottom30 {
        padding-bottom: 30px;
    }

    // Headings
    h2 {
        font-size: 42px;
        margin-bottom: 10px;

        @include breakpoint(tablet) {
            font-size: 36px;
        }

        @include breakpoint(tablet) {
            font-size: 32px;
        }
    }

    h3 {
        font-size: 26px;
        font-weight: $weight-light;
        color: rgba(0, 0, 0, 0.8);
        margin-bottom: 35px;
        line-height: 32px;

        @include breakpoint(tablet) {
            font-size: 24px;
        }

        @include breakpoint(mobile) {
            font-size: 21px;
            line-height: 28px;
            margin-bottom: 20px;
        }

    }

    h4, h5, h6 {
        font-size: 24px;
        margin-bottom: 10px;
    }
}

// Categories v
.categories-v {
    padding-top: 0;

    // Category wrapper
    .category-wrapper {
        margin-bottom: 30px;
    }
}

// Products
.products {
    padding-top: 0;
    padding-bottom: 30px;

    // Category
    .category-wrapper {
        margin-bottom: 30px;
    }

    // Paragraph
    p {
        margin: 0;
    }

    .widget.widget_usp {
        min-height: 375px;

        @include breakpoint(tablet-landscape) {
            min-height: 0;
        }
    }
}

// Related products
.related-products {
    padding-top: 35px;
    padding-bottom: 45px;
    display: inline-block;
    width: 100%;

    // Heading
    h2 {
        font-size: 21px;
        margin-bottom: 30px;
        line-height: 28px;
    }
}

// Category detail
.category-detail {
    padding: 0;

    // Filters
    .category-filters {
        margin-bottom: 20px;

        @include breakpoint(mobile) {
            position: fixed;
            z-index: 9999;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 75%;
            background-color: #fff;
            padding: 25px;
            opacity: 0;
            visibility: hidden;
            bottom: 5%;
            @include transition(all .3s);

            &._active {
                opacity: 1;
                visibility: visible;
                bottom: 90px;
            }
        }

        // Filter checkbox
        .checkbox {
            display: inline-block;
            margin-right: 10px;
            font-size: 14px;
            cursor: pointer;

            @include breakpoint(mobile) {
                width: 100%;
            }

            label {
                cursor: pointer;
            }
        }
    }
}

// Product block
.product-block {
    width: 100%;
    display: inline-block;
    line-height: 0;
    margin-bottom: 30px;
    box-shadow: 0px 7px 20px 0px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    // Image
    .product-image {

        // Img
        img {
            width: 100%;
            @include transition(all .3s);
        }
    }

    // Footer
    .product-footer {
        width: 100%;
        background-color: #fff;
        padding: 25px 30px;
        display: inline-block;
        position: relative;

        // Name
        .product-name {
            font-size: 18px;
            color: #000;
            font-weight: 400;
            line-height: 1;
            padding-right: 45px;
        }

        // Arrow wrapper
        &:after {
            width: 30px;
            height: 30px;
            background: transparent;
            border: 2px solid rgba(0, 0, 0, 0.1);
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto 0;
            right: 30px;
            content: '';
            @include border-radius(50%);
            @include transition(all .1s);
        }

        // Arrow inner
        &:before {
            width: 34px;
            height: 34px;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto 0;
            right: 30px;
            content: '';
            background-image: url('../img/icons/arrow-right.svg');
            -webkit-background-size: 5px 7px;
            background-size: 5px 7px;
            background-repeat: no-repeat;
            background-position: center center;
            @include transition(all .3s);
        }
    }

    // Related product
    &._related {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.04);
        background-color: #fff;
        margin-bottom: 0;
        cursor: pointer;

        // Image
        .related-image {
            display: inline-block;
            text-align: center;

            img {
                max-width: 100%;
            }
        }

        // Footer
        .related-footer {
            width: 100%;
            display: inline-block;
            background-color: #fff;
            padding: 20px 25px;
            line-height: 1;
            border-top: 1px solid rgba(0, 0, 0, 0.04);
            font-weight: 300;
            color: rgba(0, 0, 0, 0.8);
            text-decoration: none;

            // Name
            span {
                position: relative;
                display: inline-block;
                padding-right: 20px;

                &:after {
                    width: 4px;
                    height: 6px;
                    background-image: url('../img/icons/arrow-right.svg');
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    margin: auto 0;
                    content: '';
                }
            }
        }
    }

    // Hover
    &:hover {

        .product-image img {
            transform: scale(1.1);
        }

        // Footer
        .product-footer {

            &:after {
                border-color: rgba(0, 0, 0, 0.2);
            }

            &:before {
                //background-position: right 11px center;
            }
        }
    }
}

// Product detail
#product-detail {
    padding-top: 60px;
    padding-bottom: 35px;

    @include breakpoint(mobile) {
        padding-bottom: 0;
    }

    // Product top
    .product-top {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;

        @include breakpoint(mobile) {
            display: block;
        }
    }

    // Images
    .product-images {

        // Slick dots
        .slick-dots {
            list-style-type: none;
            padding: 0;
            margin: 0;
            position: absolute;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            bottom: 0;
            cursor: pointer;

            // List item
            li {
                display: inline-block;
                float: left;
                cursor: pointer;

                // Button
                button {
                    padding: 0;
                    font-size: 0;
                    width: 10px;
                    height: 10px;
                    border: 2px solid #fff;
                    opacity: 0.5;
                    outline: none;
                    background: transparent;
                    @include border-radius(50%);
                    @include transition(all .3s);
                    margin: 0 5px;
                    cursor: pointer;
                }

                // Active
                &.slick-active button {
                    background-color: #fff;
                    opacity: 1;
                }
            }
        }
    }

    // Product cart
    .product-cart {

        // Body
        .product-cart-body {
            width: 100%;
            background-color: #fff;
            border: 1px solid rgba(0, 0, 0, 0.04);
            padding: 48px 50px;
            height: 100%;
            position: relative;
            padding-bottom: 90px;

            @include breakpoint(tablet-landscape) {
                padding: 25px;
                padding-bottom: 80px;
            }

            // Form
            form {
                margin-bottom: 20px;
            }

            // Heading
            h1 {
                font-size: 30px;
                margin: 0;

                @include breakpoint(tablet) {
                    font-size: 21px;
                }
            }

            // Paragraph
            p {
                margin: 10px 0;
                margin-bottom: 15px;
            }

            // Footer
            .product-cart-footer {
                width: 100%;
                border-top: 1px solid rgba(0, 0, 0, 0.04);
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                padding: 30px 50px;

                @include breakpoint(tablet-landscape) {
                    padding: 25px;
                }

                // Link
                a {
                    color: rgba(0, 0, 0, 0.8);
                    text-decoration: underline;
                }
            }
        }

        // Select box
        .input-group.select {
            position: relative;
            display: inline-block;

            @include breakpoint(tablet) {
                margin-bottom: 20px;
            }

            // Arrows
            &:after {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 20px;
                width: 6px;
                height: 13px;
                content: '';
                background-image: url('../img/icons/arrows.svg');
                background-size: 100% 100%;
                background-repeat: no-repeat;
                background-position: center center;
                margin: auto;
            }

            // Select
            select {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                background-color: #fff !important;
                @include border-radius(45px);
                width: auto;
                padding: 15px 25px;
                padding-right: 40px;
                font-size: 16px;
                line-height: 1;
                border: 1px solid rgba(0, 0, 0, 0.15);
                font-weight: 300;
                outline: none;
                cursor: pointer;
                margin: 0;
                margin-right: 5px;
                color: $base-font-color1 !important;
            }
        }
    }

    // Product shortcuts
    .shortcuts {
        padding-top: 25px;
        padding-bottom: 30px;
        font-size: 14px;
        line-height: 1;
        display: none;

        @include breakpoint(mobile) {
            padding-bottom: 20px;
            padding-top: 20px;
            display: block;
        }

        // Strong
        strong {
            display: inline-block;
            width: 100%;
            margin-bottom: 5px;
            line-height: 1;
        }

        // Link
        a {
            color: $base-font-color1;
            text-decoration: underline;
            line-height: 1;
            margin-right: 20px;

            @include breakpoint(mobile) {
                margin-bottom: 10px;
                display: inline-block;
            }
        }
    }

    // Specifications
    .specifications {
        width: 100%;
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.04);
        padding: 45px 40px;
        margin-bottom: 30px;

        @include breakpoint(tablet-landscape) {
            padding: 25px;
        }

        // Heading
        h2 {
            font-size: 21px;
        }

        // Table
        table {

            // Col1
            td.col1 {
                padding-right: 35px;
                font-weight: 700;
                vertical-align: top;
            }

            @include breakpoint(mobile) {

                // Rows
                tr {
                    display: inline-block;
                    width: 100%;
                    margin-bottom: 10px;

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }

                // Cols
                td {
                    display: inline-block;
                    width: 100%;
                }
            }
        }
    }

    // Content
    .product-content {
        width: 100%;
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.04);
        padding: 45px 40px;
        margin-bottom: 30px;

        @include breakpoint(tablet-landscape) {
            padding: 25px;
        }

        // Heading
        h1, h2, h3, h4 {
            font-size: 21px;
        }
    }

    // Downloads
    .downloads {
        width: 100%;
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.04);
        padding: 45px 40px;
        margin-bottom: 30px;

        @include breakpoint(tablet-landscape) {
            padding: 25px;
        }

        // Heading
        h2 {
            font-size: 21px;
        }

        // Links
        a {
            color: $base-font-color1;
            text-decoration: none;
            position: relative;
            display: inline-block;
            padding-left: 27px;

            &:before {
                width: 15px;
                height: 19px;
                background-image: url('../img/icons/pdf.svg');
                background-repeat: no-repeat;
                background-size: 100% 100%;
                background-position: center center;
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                margin: auto 0;
            }
        }
    }

    // Widget
    .widget {

        // Heading
        h1, h2, h3, h4, h5 {
            font-size: 21px;
            line-height: 28px;
        }
    }

    // Product right
    .prod-right {
        @include breakpoint(tablet) {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
        }

        @include breakpoint(mobile) {
            display: block;
        }
    }

    // Devider
    .devider-row {
        margin-top: 30px;

        @include breakpoint(mobile) {
            margin-top: 0;
        }
    }
}

// Add to cart
.add-to-cart-overlay {
    background: rgba(54, 70, 93, 0.95);
    position: fixed;
    z-index: 999999;
    left: 0; top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 15px;
    opacity: 1;
    transition: opacity .3s ease-in;
    .inner {
        background: white;
        margin: 30px auto;
        max-height: calc(100% - 60px);
        max-width: 910px;
        overflow: auto;
        padding: 30px;
        transform: translateY(0%);
        transition: .3s ease-in;
        position: relative;

        @include breakpoint(mobile){
            padding: 60px 30px;
        }

    }
    .close {
        position: absolute;
        right: 30px;
        top: 30px;
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font-size: 0;
        padding: 0;
        margin: 0;
        line-height: 0;
        width: 32px;
        height: 32px;
        background-image: url('../img/icons/delete.svg');
        background-size: 100% 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-color: transparent;
        opacity: 0.13;
        border: none;
        box-shadow: none;
        cursor: pointer;
        @include breakpoint(mobile){
            top: 20px;
            right: 20px;
        }
    }
    form label {
        display: block;
        margin-bottom: 10px;
    }

    &._closed {
        opacity: 0;
        top: 200%;
        .inner {
            transform: translateY(50%);
        }
    }
}

// Page
section.page {
    padding: 130px 0;
    padding-bottom: 50px;

    @include breakpoint(tablet-landscape) {
        padding: 110px 0;
    }

    @include breakpoint(tablet) {
        padding: 100px 0;
        padding-bottom: 50px;
    }

    @include breakpoint(mobile) {
        padding-bottom: 30px;
    }

    // Headings
    h2 {
        font-size: 42px;
        margin-bottom: 10px;

        @include breakpoint(tablet) {
            font-size: 36px;
        }

        @include breakpoint(mobile) {
            font-size: 31px;
        }
    }

    h3 {
        font-size: 26px;
        font-weight: $weight-light;
        color: rgba(0, 0, 0, 0.8);
        margin-bottom: 35px;
        line-height: 32px;

        @include breakpoint(tablet) {
            font-size: 24px;
        }

        @include breakpoint(mobile) {
            font-size: 21px;
            line-height: 28px;
            margin-bottom: 20px;
        }
    }

    h4, h5, h6 {
        font-size: 24px;
    }

    // List
    .column:not(.widget-col){
        ul:not(.redactor-toolbar):not(.inline-edit-controls) {
        padding: 0;
        margin: 0;
        list-style-type: none;
        display: inline-block;
        margin-bottom: 30px;

        // List item
        li {
            position: relative;
            padding-left: 15px;

            // Icon
            &:before {
                width: 7px;
                height: 5px;
                background-image: url('../img/icons/arrow-right.svg');
                background-size: 100% 100%;
                background-repeat: no-repeat;
                background-position: center center;
                position: absolute;
                top: 12px;
                bottom: 0;
                left: 0;
                //margin: auto 0;
                content: '';
            }

            // Link
            a {
                color: $base-font-color1;
                text-decoration: underline;
            }
        }
    }
    }

    // Category wrappers
    .category-wrapper {
        margin-bottom: 30px;
    }

    // Widget col
    .widget-col {

        @include breakpoint(tablet) {
            margin-top: 30px;
        }

        // Inner
        .inner {
            @include breakpoint(tablet) {
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
            }

            @include breakpoint(mobile) {
                display: block;
            }
        }
    }
}

// Contact
.contact {
    padding: 130px 0;
    padding-bottom: 0px;

    @include breakpoint(tablet-landscape) {
        padding: 110px 0;
        padding-bottom: 0px;
    }

    @include breakpoint(tablet) {
        padding: 100px 0;
        padding-bottom: 0px;
    }

    .row {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;

        &:before, &:after {
            display: none;
        }

        @include breakpoint(tablet) {
            -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
        }

        @include breakpoint(mobile) {
            display: block;
        }
    }

    // Column
    .column {
        margin-bottom: 30px;

        // Inner
        .inner {
            width: 100%;
            height: 100%;
            background-color: #fff;
            border: 1px solid rgba(0, 0, 0, 0.04);
            padding: 40px 45px;
            padding-top: 110px;
            position: relative;

            @include breakpoint(tablet-landscape) {
                padding: 25px;
                padding-top: 100px;
            }

            // Icon
            &:before {
                width: 41px;
                height: 41px;
                background-position: center center;
                background-repeat: no-repeat;
                -webkit-background-size: 100% 100%;
                background-size: 100% 100%;
                position: absolute;
                top: 40px;
                left: 45px;
                content: '';

                @include breakpoint(tablet) {
                    top: 25px;
                    left: 25px;
                }
            }

            // P first
            p:first-of-type {
                font-weight: 700;
                font-size: 21px;
                margin-bottom: 20px;
                color: #000;
            }
        }

        // Phone
        &.phone .inner {

            // Phone wrapper
            .phone-wrapper a {
                font-weight: 700;
                font-size: 21px;
                color: #000;
                text-decoration: none;
            }

            // Icon
            &:before {
                background-image: url('../img/icons/contact_phone.svg');
            }
        }

        // Address
        &.address .inner {

            // Link
            a {
                color: $base-font-color1;
                text-decoration: underline;
            }

            // Icon
            &:before {
                background-image: url('../img/icons/contact_address.svg');
            }
        }

        // Openinghours
        &.openinghours .inner {

            // Paragraph
            p:not(:first-of-type) {
                margin: 0;
            }

            // Table
            table {
                width: 100%;
            }

            // Icon
            &:before {
                background-image: url('../img/icons/contact_opening.svg');
            }
        }
    }
}

// Contactform
.contactform {
    padding-top: 0;
    padding-bottom: 70px;

    // Column
    .column {

        // Inner
        .inner {
            width: 100%;
            height: 100%;
            background-color: #fff;
            border: 1px solid rgba(0, 0, 0, 0.04);
            padding: 40px 45px;
            position: relative;

            @include breakpoint(tablet-landscape) {
                padding: 25px;
            }
        }

        // Left
        &.left-col {

            // Inner
            .inner {
                p:first-of-type {
                    font-size: 21px;
                    font-weight: 700;
                    color: #000;
                    margin-bottom: 20px;
                }
            }
        }

        // Right col
        &.right-col {
            line-height: 1;

            @include breakpoint(tablet) {
                margin-top: 30px;
            }

            // Inner
            .inner {
                padding: 20px;
            }

            // Maps
            iframe {
                height: 300px !important;
            }
        }
    }
}

// Cart detail
#cart-detail {
    // Headings
    h1 {
        font-size: 42px;
        margin-bottom: 10px;

        @include breakpoint(tablet) {
            font-size: 36px;
        }

        @include breakpoint(mobile) {
            font-size: 31px;
        }
    }

    h2 {
        font-size: 26px;
        font-weight: $weight-light;
        color: rgba(0, 0, 0, 0.8);
        margin-bottom: 35px;
        line-height: 32px;

        @include breakpoint(tablet) {
            font-size: 24px;
        }

        @include breakpoint(tablet) {
            font-size: 21px;
            line-height: 28px;
        }
    }

    // Cart products
    .cart-products {
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.04);

        .vertical-align {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            flex-direction: row;

            @include breakpoint(mobile) {
                display: block;
            }
        }

        .vertical-align > [class^="col-"],
        .vertical-align > [class*=" col-"] {
            display: flex;
            align-items: center;
            justify-content: left;

            @include breakpoint(mobile) {
                display: block;
            }
        }

        // Product row
        .cart-product {
            width: 100%;
            display: inline-block;
            padding: 25px;
            border-bottom: 1px solid #ebe7e7;
            line-height: 1;

            &:last-of-type {
                border: none;
            }

            // Name
            .cart-product-name {
                font-size: 16px;
                font-weight: 700;
                color: #000;
                display: block;

                .description {
                    font-weight: 300;
                }

                @include breakpoint(mobile) {
                    padding: 15px 15px;
                }
            }

            // Amount
            .cart-product-amount {
                // Select box
                .input-group.select {
                    position: relative;
                    display: inline-block;

                    // Select
                    select {
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                        background-color: #fff !important;
                        @include border-radius(45px);
                        width: auto;
                        padding: 15px 25px;
                        padding-right: 40px;
                        font-size: 16px;
                        line-height: 1;
                        border: 1px solid rgba(0, 0, 0, 0.15);
                        font-weight: 300;
                        outline: none;
                        cursor: pointer;
                        margin: 0;
                        margin-right: 5px;
                        color: $base-font-color1 !important;
                    }
                }
            }

            // Actions
            .cart-product-actions {

                @include breakpoint(mobile) {
                    text-align: right;
                }

                // Button
                button {
                    outline: none;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    font-size: 0;
                    padding: 0;
                    margin: 0;
                    line-height: 0;
                    width: 32px;
                    height: 32px;
                    background-image: url('../img/icons/delete.svg');
                    background-size: 100% 100%;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-color: transparent;
                    opacity: 0.13;
                    border: none;
                    box-shadow: none;
                    cursor: pointer;

                    @include breakpoint(mobile) {
                        margin-top: 7px;
                    }
                }
            }

            .cart-product-image img {
                width: 100%;
            }

        }
    }

    // Actions
    .cart-actions {
        text-align: right;
        display: inline-block;
        width: 100%;
        padding: 35px 0;
        position: relative;

        @include breakpoint(mobile) {
            text-align: left;
        }

        // Strong
        strong {
            font-size: 24px;
            color: #000;
            margin-top: 15px;
            display: block;
        }

        a:not(.button) {
            color: $base-font-color1;
            font-size: 14px;
        }

        // Right wrap
        .cart-actions-rightwrap {
            text-align: center;
            display: inline-block;
            float: right;
            padding-left: 30px;

            @include breakpoint(mobile) {
                float: left;
                padding-left: 0;
                padding-top: 45px;
            }
        }

        // Left wrap
        .cart-actions-leftwrap {
            display: inline-block;
            float: right;

            @include breakpoint(mobile) {
                float: left;
                position: absolute;
                top: 20px;
                left: 0;
            }
        }
    }

    // Form
    .cart-form {
        width: 100%;
        height: 100%;
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.04);
        padding: 40px 45px;
        position: relative;
        display: none;

        @include breakpoint(mobile) {
            margin-bottom: 30px;
            padding: 25px;
        }

        // Checkbox
        .input-group.checkbox {
            margin-bottom: 20px;

            // Link
            a {
                color: $base-font-color1;
                text-decoration: underline;
            }
        }
    }

    // Widget col
    .widget-col {

        // Inner
        .inner {
            @include breakpoint(tablet) {
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
            }

            @include breakpoint(mobile) {
                display: block;
            }
        }
    }
}

// Inspiration
.inspiration {
    padding: 0;
    padding-bottom: 20px;

    // Paragraph
    p {
        margin: 0;
    }

    // Img
    img {
        width: 100%;
    }

    // Inspirations
    .inspirations {

        // Item
        .inspiration-item {
            margin-bottom: 30px;
        }
    }
}

// Category trigger (tablet and below)
.category-trigger {
    display: none;

    @include breakpoint(mobile) {
        display: inline-block;
        width: auto;
        position: fixed;
        bottom: 30px;
        padding: 5px;
        text-align: center;
        left: 0;
        right: 0;
        margin: auto;
        max-width: 150px;
        z-index: 9999;
        background-color: #fff;
        box-shadow: 0px 7px 20px 0px rgba(0, 0, 0, 0.1);
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;
    }
}

// Sitekick
body.sitekick {
    margin-top: 70px;

    // Header
    header {
        top: 70px;
    }
}
