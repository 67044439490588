// Colors
$link-color-default:		$base-font-color1;
$link-color-hover:			$base-font-color1;
$link-color-active:			$base-font-color1;
$link-color-visited:		$base-font-color1;

// Decoration
$link-decoration-default:	underline;
$link-decoration-hover:		none;
$link-decoration-active: 	none;
$link-decoration-visited: 	none;
