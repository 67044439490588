.navigation {
    width: 100%;
    background-color: #fff;
    padding: 0;
    height: 89px;
    //overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;

    @include breakpoint(tablet) {
        height: 50px;
    }

    // Container
    .container {

        @include breakpoint(tablet-landscape) {
            width: 100%;
            padding-right: 0;

            // Row
            .row {
                margin-right: 0;

                .col-xs-12 {padding-right: 0;}
            }
        }
    }

    // Logo
    .logo {
        width: 149px;
        height: 25px;
        float: left;
        line-height: 86px;
        margin-right: 30px;
        margin-top: 32px;

        @include breakpoint(tablet) {
            margin-right: 0;
            margin-top: 12px;
        }
    }

    // Main navigation
    nav.main-navigation {
        height: 89px;
        display: inline-block;

        @include breakpoint(tablet-landscape) {
            float: right;
        }

        @include breakpoint(tablet) {
            position: fixed;
            top: 50px;
            bottom: 0;
            height: 100vh;
            background-color: #fff;
            z-index: 999;
            width: 250px;
            opacity: 0;
            right: -250px;
            visibility: hidden;
            @include transition(all .3s);

            &.go {
                opacity: 1;
                visibility: visible;
                right: 0;
            }
        }

        @include breakpoint(mobile) {
            width: 280px;
            right: -280px;
        }

        // List
        ul:not(.sub) {
            list-style-type: none;
            padding: 0;
            margin: 0;
            height: 89px;

            @include breakpoint(tablet) {
                height: auto;
            }

            // List item
            li {
                display: inline-block;
                float: left;
                line-height: 89px;
                position: relative;

                @include breakpoint(tablet) {
                    width: 100%;
                    line-height: 1;
                    padding: 0;
                }

                // First item (home)
                &.first {display: none;}

                // Link
                a {
                    color: #000000;
                    text-decoration: none!important;
                    line-height: 1;
                    padding: 0 18px;
                    display: inline-block;
                    text-transform: uppercase;
                    font-size: 14px;
                    position: relative;

                    // Active, hover
                    &:before {
                        position: absolute;
                        bottom: -37px;
                        left: 0;
                        right: 0;
                        width: 0;
                        margin: 0 auto;
                        height: 4px;
                        background-color: $base-color2;
                        content: '';
                        @include transition(all .3s);
                    }

                    @media screen and (min-width: 992px) {
                        &:hover,&.active {
                            &:before {width: 100%;}
                        }
                    }

                    @include breakpoint(tablet) {
                        &.active:before {
                            width: 100%;
                            bottom: -1px;
                            height: 2px;
                        }
                    }

                    // has sub
                    &.has-sub {
                        position: relative;

                        // Icon
                        &:after {
                            background-image: url('../img/icons/arrow-down.svg');
                            background-position: center center;
                            background-repeat: no-repeat;
                            background-size: 100% 100%;
                            width: 7px;
                            height: 5px;
                            position: absolute;
                            bottom: 3px;
                            right: 7px;
                            content: '';
                            opacity: 0.2;

                            @include breakpoint(tablet) {
                                top: 0;
                                bottom: 0;
                                margin: auto 0;
                            }
                        }
                    }

                    @include breakpoint(tablet) {
                        padding: 15px 15px;
                        border-bottom: 1px solid #e6e6e6;
                        width: 100%;

                    }
                }

                // First of type
                &:nth-child(2) {
                    border-left: 1px solid #e6e6e6;

                    @include breakpoint(tablet) {
                        border: none;
                    }

                    // Link
                    a {
                        padding-left:  30px;

                        @include breakpoint(tablet) {
                            padding: 15px 15px;
                            //border-top: 1px solid #e6e6e6;
                            border-top: none;
                        }
                    }
                }

                // Cart
                &.main-navigation-cart {
                    border-left: 1px solid #e6e6e6;
                    border-right: 1px solid #e6e6e6;
                    line-height: 89px;
                    padding-top: 7px;
                    max-height: 89px;

                    @include breakpoint(tablet-landscape) {
                        border-right: 0;
                    }

                    @include breakpoint(tablet) {
                        display: none;
                    }

                    // Img
                    img {
                        width: 26px;
                        height: 21px;
                    }

                    // Link
                    a {
                        padding: 0 30px;
                        position: relative;

                        &:before {
                            bottom: -30px;
                        }

                        // Number
                        .main-navigation-cart-number {
                            padding: 3px;
                            @include border-radius(50%);
                            width: 15px;
                            height: 15px;
                            background-color: $base-color2;
                            position: absolute;
                            font-size: 10px;
                            text-align: center;
                            right:25px;
                            top:-4px;
                            font-weight: $weight-regular;
                        }
                    }
                }
            }
        }

        // Sub navigation
        ul.sub {
            position: absolute;
            z-index: 999;
            top: 103%;
            background-color: #fff;
            max-width: 250px;
            min-width: 200px;
            height: auto;
            width: 100%;
            margin: 0;
            padding: 0;
            opacity: 0;
            visibility: hidden;
            @include transition(all .3s);

            // opened
            &.opened {
                top: 100%;
                visibility: visible;
                opacity: 1;
            }

            @include breakpoint(tablet) {
                position: static;
                width: 100%;
                padding-left: 15px;
                max-width:100%;
                visibility: visible;
                opacity: 1;
                display: none;
                @include transition(none);
                min-width: 0;
            }

            // List item
            li {
                display: inline-block;
                width: 100%;
                height: auto;
                padding: 0;
                line-height: 1.2;
                border-left: none!important;
                border-right: none!important;

                // Link
                a {
                    display: inline-block;
                    width: 100%;
                    border-left: none!important;
                    border-right: none!important;
                    padding: 15px 15px!important;
                    border-bottom: 1px solid rgba(0,0,0,0.04);
                    line-height: 1.2;

                    &:before {
                        height: 1px;
                        bottom: -1px;
                    }
                }
            }
        }
    }

    // Right
    .navigation-right {
        display: inline-block;
        margin-left: 30px;
        margin-top: 23px;
        float: right;

        @include breakpoint(tablet-landscape) {
            display: none;
        }

        @include breakpoint(tablet) {
            display: block;
            margin-left: 0;
            line-height: 1;
            margin-top: 10px;
            margin-right: 20px;
        }

        @include breakpoint(mobile) {
            display: none;
        }

        // Phone
        .navigation-right-phone {
            color: #000;
            font-weight: $weight-bold;
            font-size: 18px;
            position: relative;
            padding-left: 22px;
            display: block;
            text-decoration: none;

            @include breakpoint(tablet) {
                font-size: 16px;
                padding-left: 18px;
            }

            // Phone icon
            &:before {
                width: 14px;
                height: 13px;
                background-image: url('../img/icons/phone.svg');
                -webkit-background-size:  100% 100%;
                background-size:  100% 100%;
                background-repeat: no-repeat;
                background-position: center center;
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto 0;
                left: 0;
                content: '';
            }
        }
    }

    // Navigation cart
    .navigation-cart {
        display: none;
        float: right;
        position: relative;
        padding: 0 15px;
        line-height:60px;
        margin-left: 0;
        height: 50px;
        border-left: 1px solid #e6e6e6;
        border-right: 1px solid #e6e6e6;

        @include breakpoint(tablet) {
            display: inline-block;
        }

        // Img
        img {
            width: 26px;
            height: 21px;
        }

        // Number
        .navigation-cart-number {
            padding: 3px;
            @include border-radius(50%);
            width: 15px;
            height: 15px;
            background-color: $base-color2;
            position: absolute;
            font-size: 10px;
            text-align: center;
            right:12px;
            top:6px;
            font-weight: $weight-regular;
            line-height: 1;
        }
    }

    // Navicon
    .navicon-wrapper {
        position: relative;
        width: 58px;
        height: 50px;
        float: right;
        display: none;

        @include breakpoint(tablet) {
            display: block;
        }

        // Menu icon
        .navIcon {
            width: 30px;
            height: 20px;
            cursor: pointer;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            display: none;

            @include breakpoint(tablet) {
                display: block;
            }

            // All
            * {width: 30px;@include transition(all .3s);}

            // Upper
            .naviconUpper {
                border-top: 3px solid #000;
                @include border-radius(5px);
                cursor: pointer;
                transition: all 0.5s;

                &.on {
                    transform:
                            rotate(360deg);
                    border-top: 3px solid #000;

                    opacity: 0;
                }
            }

            // inner
            .innerNavicon {
                margin-top: 5px;
                border-top: 3px solid #000;
                @include border-radius(5px);
                cursor: pointer;
                transition: all 0.5s;

                &.on {
                    transform: rotate(45deg);
                    border-top: 3px solid #000;
                }
            }

            // Lower
            .naviconLower {
                border-bottom: 3px solid #000;
                @include border-radius(5px);
                cursor: pointer;
                transition: all 0.5s;
                margin-top: 5px;

                &.on {
                    transform: translateY(-8px) rotate(-45deg);
                    border-bottom: 3px solid #000;
                }
            }
        }
    }

    // Opening
    .opening {
        float: right;
    }

    // Extra nav
    .extra-nav {
        position: absolute;
        right: 0;
        width: 282px;
        z-index: 999;
        background-color: #fff;
        top: 100px;
        border-top: 1px solid #e6e6e6;
        @include transition(all .3s);
        opacity: 0;
        visibility: hidden;

        @include breakpoint(tablet) {
            top: 75px;
        }

        &._active {
            top: 89px;
            opacity: 1;
            visibility: visible;

            @include breakpoint(tablet) {
                top: 50px;
            }
        }

        // List
        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
        }

        // Small cart
        #small-cart {

            // Heading
            .small-cart-heading {
                padding: 25px 28px;
                padding-bottom: 0;
                font-weight: 700;
            }

            // Body
            .small-cart-body {
                p {
                    padding: 0px 28px;
                    padding-bottom: 15px;
                    line-height: 1;
                }

                // Ul
                ul {
                    list-style-type: none;
                    padding: 0;
                    margin: 0;

                    // List item
                    li {
                        display: inline-block;
                        width: 100%;
                        border-bottom: 1px solid rgba(0,0,0,0.15);
                        padding: 10px 28px;

                        &:first-of-type {
                            border-top: 1px solid rgba(0,0,0,0.15);
                        }

                        // Amount
                        .amount {
                            font-weight: 700;
                            padding-right: 10px;
                        }
                    }
                }
            }

            // Footer
            .small-cart-footer {
                padding: 20px 28px;

                // Button
                .button {
                    padding: 14px 15px;
                    font-size: 14px;
                    padding-right: 35px;

                    background-color: $base-color1;
                    border: none;
                    color: #fff!important;

                    &:after {
                        background-image: url('../img/icons/arrow-right-white.svg');
                        right: 20px;
                    }
                }
            }
        }
    }
}