.button {
	width: auto;
	height: auto;
	background-color: transparent;
	transition: all .3s;
	outline: none;
	display: inline-block;
	font-size: 14px;
	border: 2px solid #000;
	padding: 17px 25px!important;
	padding-right: 40px!important;
	@include border-radius(30px!important);
	line-height: 1;
	color: #000!important;
	font-weight: $weight-bold;
	text-transform: uppercase;
	text-decoration: none;
	position: relative;
	cursor: pointer;
	@include transition(all .3s);

	// Arrow
	&:after {
		width: 5px;
		height: 7px;
		background-image: url('../img/icons/arrow-right.svg');
		-webkit-background-size:  100% 100%;
		background-size:  100% 100%;
		background-position: center center;
		background-repeat: no-repeat;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 25px;
		content: '';
		margin: auto 0;
	}

	// Arrow white
	&:before {
		width: 5px;
		height: 7px;
		background-image: url('../img/icons/arrow-right-white.svg');
		-webkit-background-size:  100% 100%;
		background-size:  100% 100%;
		background-position: center center;
		background-repeat: no-repeat;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 25px;
		content: '';
		margin: auto 0;
		opacity: 0;
		@include transition(all .3s);
	}

	// Hover
	&:hover {
		//letter-spacing: 1px;
		background-color: #000;
		color: #fff!important;

		&:before {
			opacity: 1;
			right: 20px;
		}

		&:after {
			opacity: 0;
			right: 20px;
		}
	}

	// Green
	&._green {
		background-color: $base-color1;
		border: none;
		color: #fff!important;
		
		&:after {
			background-image: url('../img/icons/arrow-right-white.svg');
		}
	}
}